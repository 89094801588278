<template>
  <div>
    <v-btn color="green" class="" icon x-large @click="dialog = true"
      ><v-icon>mdi-plus-box</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Add Expense</v-card-title>
        <v-card-text>
          <div v-if="ready">
            <v-form ref="form">
              <v-text-field
                prepend-icon="mdi-list-box"
                label="Expense Description"
                v-model="form.expense_description"
              ></v-text-field>
              <v-text-field
                prepend-icon="mdi-cash"
                label="Expense Amount"
                type="number"
                v-model="form.expense_amount"
              ></v-text-field>
              <v-text-field
                prepend-icon="mdi-account-circle"
                label="Paid by"
                type="text"
                readonly
                v-model="form.paid_by"
              ></v-text-field>
              <v-text-field
                prepend-icon="mdi-account-circle-outline"
                label="Received by"
                type="text"
                v-model="form.received_by"
              ></v-text-field>

              <v-select
                label="Expense Type"
                v-model="form.expense_type"
                :items="[
                  'Operational Expense',
                  'Maintenance',
                  'Project Expenses',
                  'Renovation Expense',
                ]"
              >
              </v-select>
              <div class="d-flex">
                <p>Date Paid:</p>
                <input type="date" v-model="form.date_paid" />
                <v-btn
                  @click="add_ex"
                  color="primary"
                  class="mr-2 ml-2"
                  :loading="loading"
                  >Add</v-btn
                >
                <v-btn @click="dialog = false"> Close</v-btn>
              </div>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  async created() {
    this.ready = false;
    this.form.paid_by = this.user.username;
    if (!this.expenses) await this.get_expenses();
    this.ready = true;
  },
  methods: {
    add_ex() {
      this.loading = true;
      if (
        this.form.date_paid == "" ||
        this.form.expense_description == "" ||
        this.form.received_by == ""
      ) {
        this.loading = false;
        return alert("Please check all fields!");
      }

      let x = window.confirm("Are you sure you want to add this expense?");
      if (x) {
        this.add_expense(this.form).then(() => {
          alert("Successfully added an expense!");
          this.$refs.form.reset();
        });
      }
      this.loading = false;
    },
    ...mapActions({
      add_expense: "expense/add_expense",
      get_expenses: "expense/get_expenses",
    }),
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  data() {
    return {
      search: "",
      loading: false,
      dialog: false,
      form: {
        expense_description: "",
        expense_amount: "",
        date_paid: "",
        received_by: "",
        paid_by: "",
        expense_type: "Operational Expense",
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
