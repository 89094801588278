<template>
  <div class="d-flex">
    <v-icon large class="mr-2 ml-2">mdi-printer</v-icon>
    <span
      >From: <input type="date" v-model="form.from_date" /> - To:
      <input type="date" v-model="form.to_date"
    /></span>
    <v-btn class="mr-3 ml-3" small @click="print_expense">Print</v-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {
        from_date: "",
        to_date: "",
      },
    };
  },
  methods: {
    print_expense() {
      var url =
        this.default_server_url +
        "/print_expense/" +
        this.form.from_date +
        "/" +
        this.form.to_date;

      window.open(url);
    },
  },
  computed: {
    ...mapGetters({
      default_server_url: "auth/default_server_url",
    }),
  },
};
</script>

<style></style>
