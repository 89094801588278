<template>
  <v-container class="mt-16">
    <div v-if="ready">
      <div class="">
        <v-card flat>
          <v-card-title class="d-flex">
            <h2>Expenses</h2>
            <v-spacer></v-spacer>
            <print-expense />
            <add-expense-modal />
            <v-btn
              color=""
              class=""
              icon
              x-large
              @click="refresh"
              :loading="loading"
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Search"
              prepend-icon="mdi-magnify"
              v-model="search"
            ></v-text-field>

            <v-data-table
              :items="expenses"
              :headers="expenses_headers"
              :search="search"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn @click="del(item)" icon color="error"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div v-else class="d-flex justify-center">
      <h2 class="text-center mt-10 mb-10">Data Table is loading...</h2>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PrintExpense from "../components/Expenses/printExpense.vue";
import AddExpenseModal from "../components/Payment/addExpenseModal.vue";

export default {
  components: { AddExpenseModal, PrintExpense },
  data() {
    return {
      search: "",
      loading: false,
      ready: true,
      expenses_headers: [
        { text: "Action", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Expense Description", value: "expense_description" },
        { text: "Expense Type", value: "expense_type" },
        { text: "Expense Amount", value: "expense_amount" },
        { text: "Date Paid", value: "date_paid" },
        { text: "Received by", value: "received_by" },
        { text: "Paid by", value: "paid_by" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      import_batches: "importBatch/import_batches",
      expenses: "expense/expenses",
    }),
  },
  methods: {
    ...mapActions({
      get_expenses: "expense/get_expenses",
      get_import_batches: "importBatch/get_import_batches",
      delete_expense: "expense/delete_expense",
    }),
    del(item) {
      let x = window.confirm("Are you sure you want to delete this?");
      if (x) {
        this.loading = true;
        this.delete_expense(item)
          .then(() => {
            alert("Sucessfully deleted an expense");
            this.loading = false;
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    refresh() {
      this.loading = true;
      this.get_expenses().then(() => {
        this.loading = false;
      });
    },
  },
  created() {
    if (this.expenses.length == 0) {
      this.ready = false;
      this.get_expenses().then(() => {
        this.ready = true;
      });
    }
  },
};
</script>

<style></style>
